import type { GetStaticPropsContext } from 'next';
import Image from 'next/image';

import { Badge, Box, Flex, Heading, Text } from '@chakra-ui/react';
import { ArrowRightIcon } from '@udacity/chakra-uds-icons';

import { ButtonLink } from '~/components/button-link';
import { Card } from '~/components/card';
import { ContentContainer } from '~/components/container';
import { SanityImage } from '~/components/sanity-image';
import { StandardHeroSection } from '~/components/standard-hero';
import { PageCategory } from '~/features/analytics/constants';
import { trackEvent } from '~/features/analytics/track-event';
import { getCatalogItemsFromServer } from '~/features/catalog/controllers/get-catalog-items-from-server';
import { DEFAULT_LANGUAGE } from '~/features/internationalization/languages';
import { PageHead } from '~/features/page/components/page-head';
import { PageLayout } from '~/features/page/components/page-layout';
import { getPage } from '~/features/page/controllers/get-page';
import { Page } from '~/features/page/models/page';
import { getPageTemplate } from '~/features/page-template/controllers/get-page-template';
import { Home, ProgramCollectionWithCatalogData } from '~/features/page-template/templates/home/components/home';
import { HomeTemplate, homeTemplateQuery, homeTemplatesSchema } from '~/features/page-template/templates/home/model';
import { getNewProgramsList } from '~/features/program/controllers/get-new-programs-list';

export default function TakeoverHomepage({
  page,
  homeTemplate,
  newPrograms,
  programCollections,
}: {
  page: Page;
  homeTemplate: HomeTemplate;
  newPrograms: ProgramCollectionWithCatalogData;
  programCollections: ProgramCollectionWithCatalogData[];
}) {
  const { homepageTakeoverConfig } = homeTemplate;

  function clickHandler(label: string) {
    trackEvent({
      type: 'click',
      name: 'Homepage CTA Clicked',
      label,
    });
  }

  return (
    <>
      <PageHead
        forcedCanonicalPath='/'
        loadChatbot={false}
        page={page}
        pageCategory={PageCategory.Home}
        additionalPageAnalytics={{
          homepage_type: 'takeover',
        }}
        nextHeadChildren={
          <>
            {homepageTakeoverConfig?.strategy === 'hero-override' && (
              <link
                as='image'
                href='/images/backgrounds/background-purple-curved-shape.png'
                rel='preload'
                type='image/png'
              />
            )}
          </>
        }
      />
      <PageLayout withoutVerticalPadding>
        <Home
          homeTemplate={homeTemplate}
          newPrograms={newPrograms}
          programCollections={programCollections}
          heroBottomSlot={
            homepageTakeoverConfig?.strategy === 'aside-section' ? (
              <Box as='section' mt={10}>
                <ContentContainer>
                  <Card
                    alignItems='center'
                    aria-labelledby='homepage-takeover-aside-section-title'
                    as='aside'
                    bgColor='blue.500'
                    border='none'
                    borderRadius='lg'
                    color='white'
                    display='flex'
                    gap={6}
                    paddingLeft={{ base: 4, md: 8 }}
                    paddingRight={4}
                    py={4}
                    flexDir={{
                      base: 'column-reverse',
                      md: 'row',
                    }}
                  >
                    <Flex
                      flex='1'
                      flexDir='column'
                      gap={4}
                      justifyContent='center'
                      sx={{
                        '&::after': {
                          content: "''",
                          display: {
                            base: 'none',
                            md: 'block',
                          },
                          borderColor: 'white',
                          borderTop: '1px solid',
                          opacity: 0.7,
                          order: 0,
                        },
                      }}
                    >
                      <Flex alignSelf='flex-start' gap={4}>
                        {homepageTakeoverConfig?.badgeText && (
                          <Badge
                            alignSelf='center'
                            whiteSpace='nowrap'
                            width='min-content'
                            display={{
                              base: 'none',
                              md: 'block',
                            }}
                          >
                            {homepageTakeoverConfig.badgeText}
                          </Badge>
                        )}
                        <Heading as='h2' id='homepage-takeover-aside-section-title' size='marketing-3xl'>
                          {homepageTakeoverConfig.title}
                        </Heading>
                      </Flex>
                      <Flex
                        alignItems='center'
                        gap={4}
                        justifyContent='space-between'
                        order={1}
                        flexDir={{
                          base: 'column',
                          md: 'row',
                        }}
                      >
                        <Text>{homepageTakeoverConfig?.description}</Text>
                        <ButtonLink
                          buttonProps={{
                            rightIcon: <ArrowRightIcon h='24px' w='24px' />,
                            size: 'icon-right',
                            width: { base: '100%', md: 'fit-content' },
                            colorScheme: 'sea-foam',
                            backgroundColor: 'accent.lime',
                            onClick: () => clickHandler(homepageTakeoverConfig.ctaLabel),
                            _hover: {
                              backgroundColor: '#ddff56',
                            },
                          }}
                          linkProps={{
                            href: homepageTakeoverConfig.ctaUrl,
                          }}
                        >
                          {homepageTakeoverConfig.ctaLabel}
                        </ButtonLink>
                      </Flex>
                    </Flex>
                    <Box
                      borderRadius='md'
                      overflow='hidden'
                      position='relative'
                      height={{
                        base: '140px',
                        md: '150px',
                      }}
                      width={{
                        base: '100%',
                        md: '270px',
                      }}
                    >
                      <SanityImage
                        alt=''
                        image={homepageTakeoverConfig?.image}
                        sizes='540px'
                        style={{
                          pointerEvents: 'none',
                          objectFit: 'cover',
                        }}
                        fill
                      />
                      {homepageTakeoverConfig?.badgeText && (
                        <Badge
                          position='absolute'
                          right={4}
                          top={4}
                          whiteSpace='nowrap'
                          width='min-content'
                          display={{
                            base: 'block',
                            md: 'none',
                          }}
                        >
                          {homepageTakeoverConfig.badgeText}
                        </Badge>
                      )}
                    </Box>
                  </Card>
                </ContentContainer>
              </Box>
            ) : undefined
          }
          heroSlot={
            homepageTakeoverConfig?.strategy === 'hero-override' ? (
              <Box bgColor='linear-gradient(257.43deg, #A75CFF 0%, #6A24BF 48.89%, #39067A 100%)'>
                <StandardHeroSection
                  containerProps={{
                    backgroundImage: 'url(/images/backgrounds/background-purple-curved-shape.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundColor: 'unset',
                  }}
                  contentSlot={
                    <>
                      {homepageTakeoverConfig.badgeText && (
                        <Badge mb={2} whiteSpace='nowrap' width='min-content'>
                          {homepageTakeoverConfig.badgeText}
                        </Badge>
                      )}
                      <Heading
                        as='h1'
                        fontWeight='500'
                        mb={4}
                        size={{
                          base: 'marketing-5xl',
                          xl: 'marketing-6xl',
                        }}
                      >
                        {homepageTakeoverConfig.title}
                      </Heading>
                      <Text mb={6}>{homepageTakeoverConfig.description}</Text>
                      <ButtonLink
                        buttonProps={{
                          rightIcon: <ArrowRightIcon h='24px' w='24px' />,
                          size: 'icon-right',
                          width: { base: '100%', md: 'fit-content' },
                          colorScheme: 'sea-foam',
                          onClick: () => clickHandler(homepageTakeoverConfig.ctaLabel),
                        }}
                        linkProps={{
                          href: homepageTakeoverConfig.ctaUrl,
                        }}
                      >
                        {homepageTakeoverConfig.ctaLabel}
                      </ButtonLink>
                    </>
                  }
                  heroLayoutContainerProps={{
                    gap: {
                      base: '24px',
                      md: '58px',
                    },
                    alignItems: 'center',
                    flexDir: {
                      base: 'column',
                      xl: 'row',
                    },
                  }}
                  imageSlot={
                    <Box position='relative'>
                      {homepageTakeoverConfig.renderNewProgramHeroSticker && (
                        <Box
                          position='absolute'
                          right={{
                            base: '-22px',
                            xl: '-40px',
                          }}
                          top={{
                            base: '-22px',
                            xl: '-36px',
                          }}
                          transform={{
                            base: 'scale(0.75)',
                            xl: 'scale(1.0)',
                          }}
                        >
                          <Image
                            alt='New Program'
                            height={140}
                            loading='eager'
                            sizes='140px'
                            src='/images/new-program-sticker.png'
                            width={140}
                            style={{
                              pointerEvents: 'none',
                              display: 'block',
                            }}
                          />
                        </Box>
                      )}
                      <Box borderRadius='42px 6px' maxWidth='640px' overflow='hidden' width='100%'>
                        <SanityImage
                          image={homepageTakeoverConfig.image}
                          loading='eager'
                          quality={80}
                          style={{
                            pointerEvents: 'none',
                          }}
                        />
                      </Box>
                    </Box>
                  }
                />
              </Box>
            ) : undefined
          }
        />
      </PageLayout>
    </>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  // Stop additional locale pages except the default locale from being built
  if (locale !== DEFAULT_LANGUAGE) {
    return {
      notFound: true,
    };
  }

  let [page, homeTemplate, newProgramKeys] = await Promise.all([
    getPage('/'),
    getPageTemplate<HomeTemplate>({
      templateName: 'Home Page',
      templateQuery: homeTemplateQuery,
      templateZodSchema: homeTemplatesSchema,
    }),
    getNewProgramsList(),
  ]);

  // Note: if no configuration is found, we shouldn't render this page.
  if (!homeTemplate.homepageTakeoverConfig?.strategy) {
    return {
      notFound: true,
    };
  }

  const newPrograms = { title: 'Newly Released Courses', programs: getCatalogItemsFromServer(newProgramKeys ?? []) };

  let programCollections: ProgramCollectionWithCatalogData[] =
    homeTemplate.programCollections?.map((programCollection) => {
      const programKeys = programCollection.programs.map((program) => program.key);

      return {
        title: programCollection.title,
        programs: getCatalogItemsFromServer(programKeys),
      };
    }) ?? [];

  return {
    props: {
      page,
      homeTemplate,
      newPrograms,
      programCollections,
    },
  };
}
