import { ReactNode } from 'react';

import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';

import { ContentContainer } from './container';

export type StandardHeroSectionProps = {
  imageSlot?: ReactNode;
  backgroundImageSlot?: ReactNode;
  backgroundTheme?: 'light' | 'dark';
  alertSlot?: ReactNode;
  contentSlot: ReactNode;
  containerProps?: BoxProps;
  heroLayoutContainerProps?: FlexProps;
  contentSlotContainerProps?: FlexProps;
};

export function StandardHeroSection({
  contentSlot,
  imageSlot = null,
  backgroundImageSlot = null,
  backgroundTheme = 'dark',
  alertSlot = null,
  containerProps,
  heroLayoutContainerProps,
  contentSlotContainerProps,
}: StandardHeroSectionProps) {
  return (
    <Box
      as='section'
      backgroundColor={backgroundTheme === 'dark' ? 'blue.800' : 'accent.sky.100'}
      color={backgroundTheme === 'dark' ? 'white' : 'black'}
      display='flex'
      overflow='hidden'
      position='relative'
      padding={{
        base: '40px 0px',
        md: '80px 0px',
      }}
      {...containerProps}
    >
      {backgroundImageSlot}
      <ContentContainer marginY='0px' position='relative'>
        {alertSlot}
        <Flex
          gap='24px'
          flexDir={{
            base: 'column',
            md: 'row',
          }}
          {...heroLayoutContainerProps}
        >
          <Flex flex='1 1' flexDir='column' gap='16px' {...contentSlotContainerProps}>
            {contentSlot}
          </Flex>
          {imageSlot}
        </Flex>
      </ContentContainer>
    </Box>
  );
}
